import { device } from 'src/styles/breakpoints';
import { grayscale, orange, white } from 'src/styles/colors';
import styled from 'styled-components';

type BgFooter = {
  theme: string;
}

type Colors = {
  background: string;
  color: {
    default: string;
    address: string;
    disclamer: string;
    contactType: string;
    icon: string;
  };
  border: string;
}

type Theme = {
  [params: string]: Colors;
  light: Colors;
  dark: Colors;
}

const theme: Theme = {
  light: {
    background: white,
    color: {
      default: grayscale['500'],
      address: grayscale['400'],
      disclamer: grayscale['400'],
      contactType: grayscale['400'],
      icon: grayscale['400'],
    },
    border: grayscale['200'],
  },
  dark: {
    background: grayscale['500'],
    color: {
      default: white,
      address: grayscale['200'],
      disclamer: grayscale['200'],
      contactType: grayscale['100'],
      icon: grayscale['300'],
    },
    border: grayscale['500'],
  },
}

export const FooterStyle = styled.div<BgFooter>`
  background: ${(props: BgFooter) => theme[props.theme].background};
  border-top: 1px solid ${(props: BgFooter) => theme[props.theme].border};
  color: ${(props: BgFooter) => theme[props.theme].color.default};
  font-size: 12px;
  padding-top: 40px;

  h2 {
    color: ${(props: BgFooter) => theme[props.theme].color.default};
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  a {
    color: ${(props: BgFooter) => theme[props.theme].color.default};
    font-weight: 400;

    &:hover,
    &:focus {
      color: ${orange.extra};
    }
  }

  .row:first-child {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(1, 1fr);
    flex-direction: initial;

    @media ${device.tablet} {
      grid-template-rows: repeat(1, 1fr);
      grid-template-columns: repeat(3, 1fr);
    }

    @media ${device.desktopLG} {
      grid-template-columns: repeat(4, 1fr);
    }

    .for-you, .need-help, .contact {
      grid-template-rows: repeat(1, 1fr);
      grid-template-columns: repeat(2, 1fr);
      display: grid;

      @media ${device.tablet} {
        grid-template-rows: auto;
        grid-template-columns: auto;
        display: block;
      }

      div {
        &:last-child {
          margin-top: 37px;

          @media ${device.tablet} {
            margin-top: 0;
          }
        }
      }
    }
  }

  ul {
    li {
      line-height: 13px;
      margin-bottom: 24px;
      @media ${device.tablet} {
        line-height: 15px;
      }      
    }

    &.list-phones {
      li {
        color: ${(props: BgFooter) => theme[props.theme].color.contactType};
        font-size: 10px;
        margin-bottom: 20px;
        @media ${device.tablet} {
          font-size: 12px;
        }

        a {
          display: block;
          font-size: 12px;
          font-weight: 700;

          @media ${device.tablet} {
            font-size: 14px;
          }
        }
      }
    }
  }
 
  svg {
    max-width: 100%;
    fill: ${(props: BgFooter) => theme[props.theme].color.default};
    
    &:hover {
      fill: ${orange.extra};
    }   
  } 
  
  .social-network {

    .list-inline-item:not(:last-child) {

      @media ${device.tablet} {
        margin-right: 36px;
      }

      @media ${device.desktopLG} {
        margin-right: 18px;
      }
    }

    ul {
      align-items: center;
      display: flex;
      justify-content: space-around;

      @media ${device.tablet} {
        align-items: initial;
        justify-content: initial;
      }

      li {
        svg {
          margin: 0;
          @media ${device.desktopLG} {
            margin: 0 0 24px 0;
          }
        }
      }
    }
  }

  .disclamer {
    p {
      color: ${(props: BgFooter) => theme[props.theme].color.disclamer};
    }
  }

  .language {
    p {
      color: ${(props: BgFooter) => theme[props.theme].color.disclamer};
    }
  }

  .sub-footer {
    font-size: 14px;
    padding-top: 28px;
    padding-bottom: 30px;

    .address {
      &:hover {
        svg {
          stroke: ${orange.extra};
        }
      }
      svg {
        max-width: 100%;
        stroke: ${(props: BgFooter) => theme[props.theme].color.icon};
        fill: ${white};
        margin-left: -2px;
        
        &:hover {
          stroke: ${orange.extra};
        }   
      } 
    }

    @media ${device.tablet} {
      padding-top: 32px;
    }
    @media ${device.desktopLG} {
      padding-top: 24px;
    }

    ul {
      margin-bottom: 20px;

      @media ${device.tablet} {
        margin-bottom: 31px;
      }

      @media ${device.desktopLG} {
        margin-bottom: 24px;
      }

      li {
        &.list-inline-item {
          margin: 0 8px 8px 0;

          @media ${device.tablet} {
            margin-left: 0;
            margin-right: 16px;
          }
        }
      }
    }

    address {
      margin-bottom: 0;
      line-height: 14px;
      margin-left: -2px;

      @media ${device.desktopLG} {
        margin-left: -1px;
      }

      p {
        color: ${(props: BgFooter) => theme[props.theme].color.address};
      }
    }
  }

  .title-font {
    font-family: 'Sora', Helvetica, sans-serif;
    height: 20px;
    line-height: 20px;
  }

  .selo-nasdaq {
    width: 228px;
    border: solid 2px ${orange.base};
    border-radius: 50px;
    padding: 15px;

    @media ${device.tablet} {
      margin-top: 0;
    }
  }

  .bureau-veritas {
    svg {
      path {
        fill: ${(props: BgFooter) => theme[props.theme].color.default};
      }
    }
  }

  .list-phones {
    svg {
      path {
        fill: ${(props: BgFooter) => theme[props.theme].color.address};
      }
    }
  }
`
