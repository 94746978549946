import React, { memo } from 'react'
import { translate } from 'react-i18next'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerFooter'

import Facebook from '@interco/icons/build-v4/orangeds/MD/facebook'
import Instagram from '@interco/icons/build-v4/orangeds/MD/instagram'
import Linkedin from '@interco/icons/build-v4/orangeds/MD/linkedin'
import Twitter from '@interco/icons/build-v4/orangeds/MD/twitter'
import Youtube from '@interco/icons/build-v4/orangeds/MD/youtube'
import Inter from '@interco/icons/core/brands/ic_inter'

import SocialNetworkJson from 'config/Layout/SocialNetwork/SocialNetwork.json'

import { IconProps, ISocialNetworkProps, SocialNetworkContainerProps } from '../Layout/Footer/Footer.interface'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import Content from './style'

function SocialNetwork ({ color, t, showTitle, type, width, height }: ISocialNetworkProps) {
  const [ sendDatalayerFooterEvent ] = useDataLayer()
  const screenWidth = useWidth()

  const icons: IconProps = {
    inter: <Inter className={type} width={width} height={height} color={color} />,
    instagram: <Instagram className={type} width={width} height={height} color={color} />,
    linkedin: <Linkedin className={type} width={width} height={height} color={color} />,
    facebook: <Facebook className={type} width={width} height={height} color={color} />,
    twitter: <Twitter className={type} width={width} height={height} color={color} />,
    youtube: <Youtube className={type} width={width} height={height} color={color} />,
  }

  return (
    <Content>
      <div className='social-network'>
        {(showTitle) && <span className='font-citrina d-block fw-600 mb-4 mb-lg-3 fs-16 text-grayscale--500'>{t('social_network.follow_inter')}</span>}
        <ul className='list-inline'>
          {
            SocialNetworkJson.map((item: SocialNetworkContainerProps, index: number) => {
              const icon = type === 'colored' ? item.icon_colored : item.icon
              const itemLink = item.icon === 'inter' && screenWidth > WIDTH_MD ? item.slug : item.link
              return (
                <li key={'li--' + index} className='list-inline-item'>
                  <a
                    key={'item--' + index} href={itemLink}
                    target={item.icon === 'inter' ? '_self' : '_blank'} rel='noreferrer'
                    title={item.title} data-footer={item.namedata}
                    onClick={() => {
                      sendDatalayerFooterEvent({
                        section: t('social_network.follow_inter'),
                        element_action: 'click button',
                        element_name: item.name,
                        redirect_url: item.link,
                        c_page: window.location.href,
                      })
                    }}
                  >
                    <span className='sr-only'>{item.name}</span>
                    {icons[icon]}
                    <p className='fs-16 fw-300 ml-3 text-gray--light'>{item.slug}</p>
                  </a>
                </li>
              )
            })
          }
        </ul>
      </div>
    </Content>
  )
}

SocialNetwork.defaultProps = {
  color: 'orange--base',
  showTitle: true,
  type: '',
  width: 22,
  height: 22,
}

export default translate('Components')(memo<ISocialNetworkProps>((SocialNetwork)))
